import axios from '@/api/axios';
import lang from '@/lang';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {

  },
})
  .get({
    action: 'getProducts',
    property: 'products',
    path: '/shop/products',
  })
  .post({
    action: 'addProduct',
    path: '/shop/products',
  })
  .put({
    action: 'reportDelivery',
    path: '/shop/orders/delivered',
  })
  .get({
    action: 'getOrders',
    path: '/shop/orders',
  })
  .post({
    action: 'getOrdersReport',
    requestConfig: {
      responseType: 'blob',
    },
    path: '/shop/orders/report',
  })
  .post({
    action: 'updateOrders',
    path: () => '/shop/orders',
  })
  .get({
    action: 'getPayerOffer',
    path: () => '/shop/orders/offer',
  })
  .get({
    action: 'getPayeeOffer',
    path: () => '/shop/orders/offer/all',
  })
  .put({
    action: 'updateProduct',
    path: ({ productId }) => `/shop/products/${productId}`,
  })
  .get({
    action: 'getProduct',
    path: ({ productId }) => `/shop/products/${productId}`,
  })
  .delete({
    action: 'removeProduct',
    path: ({ productId }) => `/shop/products/${productId}`,
  })
  .get({
    action: 'getProductSets',
    path: '/shop/product-sets',
  })
  .get({
    action: 'getProductSet',
    path: ({ productSetId }) => `/shop/product-sets/${productSetId}`,
  })
  .post({
    action: 'addProductSet',
    path: '/shop/product-sets',
  })
  .put({
    action: 'updateProductSet',
    path: ({ productSetId }) => `/shop/product-sets/${productSetId}`,
  })
  .delete({
    action: 'removeProductSet',
    path: ({ productSetId }) => `/shop/product-sets/${productSetId}`,
  })
  .post({
    action: 'addProductSetAvailability',
    path: '/shop/product-sets/availability',
  })
  .get({
    action: 'getProductSetAvailabilities',
    path: '/shop/product-sets/availability',
  })
  .delete({
    action: 'removeProductSetAvailability',
    path: ({ availabilityId }) => `/shop/product-sets/availability/${availabilityId}`,
  })
  .get({
    action: 'getProductSetAvailability',
    path: ({ availabilityId }) => `/shop/product-sets/availability/${availabilityId}`,
  })
  .post({
    action: 'validateShopImport',
    path: () => '/shop/import/validate',
  })
  .post({
    action: 'uploadShopImport',
    path: () => '/shop/import',
  })
  .get({
    action: 'getShopConfig',
    path: () => '/shop/config',
  })
  .put({
    action: 'toggleShopEnabled',
    path: () => '/shop/config/enabled',
  })
  .put({
    action: 'updateShopCatalog',
    path: () => '/shop/catalogs',
  })
  .put({
    action: 'toggleShopScopeType',
    path: () => '/shop/config/scope-type',
  })
  .put({
    action: 'toggleShopOrderAutoCancel',
    path: () => '/shop/config/order-auto-cancel',
  })
  .post({
    action: 'addProductSetType',
    path: () => '/shop/config/types',
  })
  .put({
    action: 'updateProductSetType',
    path: ({ id }) => `/shop/config/types/${id}`,
  })
  .delete({
    action: 'removeProductSetType',
    path: ({ id }) => `/shop/config/types/${id}`,
  })
  .get({
    action: 'getReportSubscriptions',
    path: () => '/shop/config/subscriptions',
  })
  .post({
    action: 'addReportSubscription',
    path: () => '/shop/config/subscriptions',
  })
  .put({
    action: 'updateReportSubscription',
    path: ({ id }) => `/shop/config/subscriptions/${id}`,
  })
  .put({
    action: 'toggleReportSubscription',
    path: ({ id }) => `/shop/config/subscriptions/${id}/send`,
    requestConfig: {
      responseType: 'blob',
    },
  })
  .delete({
    action: 'removeReportSubscription',
    path: ({ id }) => `/shop/config/subscriptions/${id}`,
  })
  .getStore();

store.getters = {
  products: (s) => s.products,
  ordersReportTypes: () => [
    {
      type: 'DailyOrders',
      icon: 'far fa-user-tag',
      text: lang.t('shop.reports.type.dailyOrders'),
    },
    {
      type: 'DailyProductSetSummary',
      icon: 'far fa-boxes-stacked',
      text: lang.t('shop.reports.type.dailyProductSetSummary'),
    },
  ],
};

export default store;
