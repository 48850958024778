import oidcSettings from '@/api/oidc';
import Vue from 'vue';
import Vuex from 'vuex';
import { vuexOidcCreateStoreModule } from 'vuex-oidc';

import payer from '@/modules/payer/store';
import access from './modules/access';
import accounting from './modules/accounting';
import config from './modules/config';
import currency from './modules/currency';
import payGateway from './modules/payGateway';
import payee from './modules/payee';
import payers from './modules/payers';
import payersGroups from './modules/payersGroups';
import payersProperties from './modules/payersProperties';
import payments from './modules/payments';
import shop from './modules/shop';

import bankAccount from './modules/bankAccount';
import bankStatements from './modules/bankStatements';
import billCategories from './modules/billCategories';
import billPayers from './modules/billPayers';
import billingPlans from './modules/billingPlans';
import billingScopes from './modules/billingScopes';
import bills from './modules/bills';
import fileImport from './modules/fileImport';
import forms from './modules/forms';
import messages from './modules/messages';
import notify from './modules/notify';
import reports from './modules/reports';
import transactions from './modules/transactions';
import userPayerInvites from './modules/userPayerInvites';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: true,
  modules: {
    oidc: vuexOidcCreateStoreModule(oidcSettings, {
      namespaced: true,
      isAuthenticatedBy: 'access_token',
      publicRoutePaths: ['/oidc-callback'],
    }),
    bankAccount,
    currency,
    notify,
    config,
    payee,
    payers,
    payments,
    messages,
    shop,
    access,
    bills,
    billingPlans,
    billPayers,
    billingScopes,
    billCategories,
    payersGroups,
    payersProperties,
    fileImport,
    reports,
    transactions,
    bankStatements,
    userPayerInvites,
    payGateway,
    forms,
    accounting,
    ...payer,
  },
});

export default store;
